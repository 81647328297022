#components-reconnect-modal, .components-reconnect-show, .components-reconnect-failed, .components-reconnect-rejected {
    display: none !important;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-19-7DRs5.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-19a7DRs5.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-1967DRs5.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-19G7DRs5.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-1927DRs5.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DRs5.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(../fonts/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}

iframe {
    border: none;
}

* {
    outline: none;
}
.container-1170 {
	max-width: 1280px;
	margin: auto;
	padding: 0 10px;
}
h2 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}
h1.special-title {
    font-size: 38px;
    color: #333333;
    text-align: center;
    font-weight: 700;
    margin-top: .5em;
    margin-bottom: .5em;
}

.gift-h1 {
    font-size: 26px;
    margin: 5px 0;
    text-align: center;
}

h2.special-title {
    font-size: 40px;
    color: #333333;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 50px;
}

.preventcopy {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: 606px) {
	h1.special-title, h2.special-title {
        margin-top: 50px;
		font-size: 20px;
	}
}

.bottom-line {
	position: relative;
    width: 100%;
    height: 4px;
    background: #88DE3F;
    box-shadow: 0 1px 2px rgba(51, 83, 0, 0.8);
	z-index: 2;
}
.background-five {
	position: relative;
	background: #4e8ab3;
	box-shadow: 0 3px 45px 3px rgba(0,0,0,0.28);
	padding: 10px 0 30px 0;
}

.questions {
	font-size: 50px;
	text-align: center;
	color: #005f87;
	padding-top: 40px;
    font-weight: 700;
}
.questions a {
	color: #005f87;
	text-decoration: none;
}
.call {
	font-size: 50px;
	text-align: center;
	color: #00c22c;
}
.call a {
    color: #00c22c;
    text-decoration: none;
}
.hot-grid {
	display: grid;
	grid-template-columns: 1fr 150px;
}
@media (max-width: 917px) {
	.hot-grid {
		display: grid;
		grid-template-columns: 1fr;
	}
}
.questions-top {
    font-size: 32px;
    text-align: center;
    color: #005f87;
}

    .questions-top a {
        color: #005f87;
        text-decoration: none;
        text-transform: uppercase;
    }

.call-top {
    font-size: 32px;
    text-align: center;
    color: #00c22c;
}
    .call-top a {
        color: #00c22c;
        text-decoration: none;
        text-transform: uppercase;
    }
.calendar-top {
    margin-top: 10px;
    padding-bottom: 10px;
}
.calcuator-top {
    padding-top: 10px;
    margin-bottom: 5px;
}
.no-form {
    color: red;
}
.fixprice {
    font-size: 24px;
    color: #005d8a;
}
.belii {
    font-size: 22px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding-top: 40px;
}
.otstup-niz {
	padding-top: 30px;
}
.button-top {
	position: relative;
	margin-top: -80px;
	z-index: 2;
	display: flex;
	justify-content: center;
    cursor: pointer;
}
.schd2 {
	margin-top: 5px;
	font-size: 16px;
	color: #fff;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	width: 290px;
}
.belii2 {
	font-size: 20px;
	color: #fff;
}
.bottom-frame {
	position: relative;
	margin-top: -60px;
}
.bottom-frame img {
	margin: auto;
}

.tel-bottom {
    color: #89d944;
    font-weight: 600;
    padding-bottom: 10px;
}

.tel-bottom a {
	color: #f3f3f3;
	font-weight: 600;
	font-size: 38px;
	padding-bottom: 5px;
    text-decoration: none;
}
.white {
	color: #ffffff;
	width: 48%;
}
.white-p {
	color: #ffffff;
}
.white-p a {
	color: #ffffff;
	text-decoration: underline;
}
.white-p a:hover {
	color: #ffffff;
	text-decoration: none;
}
.white-p a:visited  {
	color: #ffffff;
	text-decoration: underline;
}
.white-p a:active  {
	color: #ffffff;
	text-decoration: underline;
}

.bottomMenu {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 10px;
}

.bottomMenu a {
    padding-bottom: 2px;
    margin-bottom: 2px;
    text-decoration: none;
    border-bottom: 1px dashed #629ac0;
}

.condensed {
	color: #ffffff;
	font-size: 16px;
	flex: 1 1 190px;
}
.none {
	list-style-type: none;
}
.none a {
	color: #fff;
	text-decoration: underline;
}
.none a:hover {
	color: #fff;
	text-decoration: none;
}
.none a:visited {
	color: #fff;
	text-decoration: underline;
}
.none a:active {
	color: #fff;
	text-decoration: underline;
}
.doble-background {
	background: #4e8ab3;
}
.doble-background:before{
    background: #fbfbfb;
    height: 70px;
    width: 100%;
    border-radius: 0 0 100% 100%;
    display: inline-block;
    content: '';
}
.cpvv img {
	margin-top: -35px;
}
.site-info {
    margin-top: 10px;
    text-align: center;
}
.site-info a {
    color: #ffffff;
}
.site-info a:hover {
    color: #ffffff;
}
.site-info a:active {
    color: #ffffff;
}
.site-info a:visited {
    color: #ffffff;
}
.countdown {
	text-align: center;
}
.position {
    text-align: center;
    display: block !important;
    font-size: 45px;
	line-height: 65px;
    color: #58575c;
    float: left;
}
.countdownHolder {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
}
.countDiv {
    float: left;
    width: 10px;
    height: 60px;
}
.countDays, .countHours, .countMinutes, .countSeconds {
	width: 65px;
	height: 60px;
	background: url(../images/chet2.webp) no-repeat;
    background-size: contain;
	display: flex;
	justify-content: center;
	align-items: center;
}

.podpisat {
	color: #005f87;
	font-size: 22px;
}
p {
	text-align: justify;
}
h2.list-header {
	font-size: 24px;
	text-transform: none;
}
h2.list-header-16 {
	font-size: 16px;
	text-decoration: none;
}
.list-header-16 a {
	text-decoration: none;
	color: #333333;
}
h2.list-header-16n {
	font-size: 16px;
	text-decoration: none;
}
.list-header-16n a {
	text-decoration: none;
	color: #333333;
}

.phone-70 input[type=text], #changeInput input[type=text] {
    border: 3px solid #d0d0d0;
    font-size: 24px;
}

/*
Новая верстка
 */
body {
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    background: #fbfbfb;
    font-size: 16px;
}

header {
    background: url(../images/background-four.webp) repeat;
}

img {
    max-width: 100%;
    height: auto;
}
.top-line {
    position: relative;
    width: 100%;
    height: 4px;
    background: #88DE3F;
    box-shadow: 0 1px 2px rgba(51, 83, 0, 0.8);
    z-index: 1;
}
.plastic-windows {
	position: relative;
	margin-right: -20px;
	margin-top: -1px;
	z-index: 2;
}
.balconies {
	position: relative;
	margin-left: -20px;
	margin-top: -1px;
}
.plastic-windows-2 {
	position: relative;
	margin-right: -20px;
	z-index: 2;
	align-self: flex-end;
}
.balconies-2 {
	position: relative;
	margin-left: -20px;
	align-self: flex-end;
}
.plastic-windows-2 a, .balconies-2 a {
	display: flex;
	align-items: flex-end;
}
.header-content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1280px;
    margin: auto;
	display: -ms-grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.logo {
    grid-column: 1 / 6;
    grid-row: 1;
    align-self: center;
	padding: 0;
	-ms-grid-column: 1;
	-ms-grid-column-span: 6;
	-ms-grid-column-align: start;
	-ms-grid-row: 1;
}
.main-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    grid-column: 1 / 13;
    grid-row: 1;
	align-self: start;
	-ms-grid-column: 1;
	-ms-grid-column-span: 13;
	-ms-grid-row-align: start;
	-ms-grid-row: 1;
}
.main-buttons-2 {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}
.tagline {
    font-size: 27px;
    text-transform: uppercase;
    color: #005f87;
    align-self: end;
    grid-column: 4 / 10;
    grid-row: 1;
    text-align: center;
	margin-bottom: 7px;
	-ms-grid-column: 1;
	-ms-grid-column-span: 13;
	-ms-grid-row-align: end;
	-ms-grid-row: 1;
}
.right-top {
    grid-column: 7 / 13;
    grid-row: 1;
    justify-self: end;
	padding: 15px 0 5px;
	-ms-grid-column: 7;
	-ms-grid-column-span: 6;
	-ms-grid-column-align: end;
	-ms-grid-row: 1;
}

.phone a {
    font-size: 44px;
    color: #0a729e;
    font-weight: 700;
    background: url(../images/phone-ico.webp) no-repeat left center;
    padding-left: 55px;
    text-decoration: none;
}

.main-box {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1280px;
    margin: 0 auto;
    background: url(../images/hot-sale.webp) center bottom no-repeat, url(../images/right-window.webp) right center no-repeat;
    min-height: 550px;
}

#hot-article {
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
	padding: 10px 25px;
	margin-bottom: 25px;
	background-color: rgba(141, 223, 70, 0.09);
}

.sale-70-article {
	font-size: 44px;
	color: #0a729e;
	font-weight: 700;
	margin-bottom: 15px;
	text-align: center;
}

.green-70-article {
	font-size: 44px;
	color: #109e42;
	font-weight: 700;
	margin-bottom: 15px;
	text-align: center;
}

.sale-70 {
    font-size: 44px;
    color: #0a729e;
    font-weight: 700;
    width: 50%;
    margin-top: 20px;
	margin-bottom: 1px;
	text-align: center;
}

.green-70 {
    font-size: 34px;
    color: #109e42;
    font-weight: 700;
    width: 45%;
    margin-top: 5px;
}

.phone-70 {
    width: 40%;
}

.big-sale {
    font-size: 150px;
    color: #0a729e;
	font-weight: 700;
}

@media (max-width: 1000px) {
    .sale-70 {
        font-size: 40px;
        color: #0a729e;
        font-weight: 700;
        width: 50%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .green-70 {
        font-size: 40px;
        color: #109e42;
        font-weight: 700;
        width: 40%;
        margin-top: 5px;
    }

    .phone-70 {
        width: 40%;
    }

    .big-sale {
        font-size: 68px;
        color: #0a729e;
        font-weight: 700;
    }
}

@media (max-width: 936px) {
    .sale-70 {
        font-size: 32px;
        color: #0a729e;
        font-weight: 700;
        width: 50%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .green-70 {
        font-size: 32px;
        color: #109e42;
        font-weight: 700;
        width: 40%;
        margin-top: 5px;
    }

    .phone-70 {
        width: 40%;
    }

    .big-sale {
        font-size: 48px;
        color: #0a729e;
        font-weight: 700;
    }
}

@media (max-width: 860px) {
    .main-box {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 1280px;
        margin: 0 auto;
        background: url(../images/hot-sale.webp) center bottom / 675px 450px no-repeat, url(../images/right-window.webp) right center / 400px 444px no-repeat;
        min-height: 550px;
    }

    .sale-70 {
        font-size: 32px;
        color: #0a729e;
        font-weight: 700;
        width: 50%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .green-70 {
        font-size: 32px;
        color: #109e42;
        font-weight: 700;
        width: 40%;
        margin-top: 5px;
    }

    .phone-70 {
        width: 40%;
    }

    .big-sale {
        font-size: 48px;
        color: #0a729e;
        font-weight: 700;
    }
}

@media (max-width: 614px) {
    .main-box {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 1280px;
        margin: 0 auto;
        background: url(../images/hot-sale.webp) right bottom / 350px 233px no-repeat, url(../images/right-window.webp) left bottom / 250px 225px no-repeat;
        min-height: 550px;
    }

    .sale-70 {
        font-size: 32px;
        color: #0a729e;
        font-weight: 700;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .green-70 {
        font-size: 32px;
        color: #109e42;
        font-weight: 700;
        width: 100%;
        margin-top: 5px;
    }

    .phone-70 {
        width: 100%;
    }

    .big-sale {
        font-size: 48px;
        color: #0a729e;
        font-weight: 700;
    }
}

.city-code {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.city-code a {
    color: #109e42;
    text-decoration: none;
}

.wood-texture {
    background: linear-gradient(to right, #b9b9b9, #b9b9b9, #b9b9b9);
    box-shadow: 0 0 13px 3px rgba(0,0,0,0.27);
    padding-top: 15px;
    padding-bottom: 15px;
}
.slider-container {
    display: flex;
    flex-flow: row wrap;
	justify-content: space-around;
    max-width: 1280px;
    margin: auto;
}
.slider {
    width: 68%;
}
.calling-gage {
    width: 30%;
	display: flex;
	justify-content: flex-end;

}

@media (max-width: 700px) {
    .slider {
		width: 100%;
	}
	.calling-gage {
		width: 100%;
		justify-content: center;
	}
}
.percent {
    padding: 10px 20px 10px 20px;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
    max-width: 1280px;
    margin: 30px auto 0;
    display: grid;
    grid-template-columns: 150px 1fr;
    background: rgb(0, 95, 136);
    color: #fff;
}
.large-percent {
    font-size: 88px;
}
.small-percent {
    font-size: 38px;
    text-align: right;
}

@media (max-width: 514px) {
    .percent {
        padding: 10px 20px 10px 20px;
        box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
        max-width: 1280px;
        margin: 30px auto 0;
        display: grid;
        grid-template-columns: 1fr;
        background: rgb(0, 95, 136);
        color: #fff;
    }
}

@media (max-width: 700px) {
    .advantage {
	width: 98%;
	margin: 1%;
}
}
#profiles {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	max-width: 1280px;
	margin: auto;
    margin-bottom: 25px;
}
.videos {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	max-width: 1280px;
	margin: auto;
}
@media (max-width: 460px) {
	.videos {
		grid-template-columns: 1fr;
	}
}

.main-content {
    padding: 0 20px;
	max-width: 1280px;
	margin: auto;
}

.stage-of-work {
    display: grid;
    grid-template-columns: 1fr 246px;
    grid-template-areas:
        "text image";
    align-items: center;
    justify-items: center;
    grid-gap: 30px;
}

.stage-of-work > div:nth-child(1) {
    grid-area: text;
}

.stage-of-work > div:nth-child(2) {
    grid-area: image;
}

.stage-of-work-left {
    display: grid;
    grid-template-columns: 246px 1fr;
    align-items: center;
    justify-items: center;
    grid-gap: 30px;
}

@media (max-width: 614px) {
    .stage-of-work {
        grid-template-columns: 1fr;
        grid-template-areas:
                "image"
                "text";
    }

    .stage-of-work-left {
        grid-template-columns: 1fr;
    }
}

.certificates {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	max-width: 1280px;
	margin: auto;
}

.board {
    text-align: center;
    box-shadow: 0 0 8px 3px rgba(0,0,0,.1);
    padding: 10px;
    background: #eeefeb;
    border-top: 12px solid #7dcc38;
    border-bottom: 12px solid #0086b6;
}
.call-installer {
    border: none;
    background: none;
    cursor: pointer;
}
    .call-installer:hover {
        opacity: .9;
    }

.add-review {
    padding: 5px 30px;
    background-color: #006392;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
}
.main-reviews {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1280px;
    margin: auto;
}
.main-review {
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	flex: 1 1 300px;
	background: #ffffff;
	box-shadow: inset -3px 3px 25px -5px rgba(0,0,0,0.37);
	margin: 10px;
	padding: 20px;

}
@media (min-width: 761px) {
	.dop-content {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
	}

	.left-content {
		width: 65%;
		margin-right: 4%;
	}

        .left-content img {
            margin-bottom: 15px;
            padding: 4px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 4px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

	.right-content {
		width: 30%;
		margin-left: 1%;
		display: flex;
        flex-flow: column wrap;
		align-items: center;
	}
}
@media (max-width: 760px) {
	.dop-content {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		padding-left: 20px;
		padding-right: 20px;
	}
	.left-content {
		width: 100%;
		margin-bottom: 10px;
	}
	.left-content img {
		margin-bottom: 15px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;
	}
	.right-content {
		width: 100%;
		display: flex;
		flex-flow: column wrap;
        align-items: center;
	}
}
.nestandart-list {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.nestandart {
	flex-basis: 400px;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
    margin-bottom: 20px;
}

    .nestandart img {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    }

    .container-footer {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        max-width: 1280px;
        margin: auto;
    }

.footer-left {
	flex: 1 1 300px;
}

.footer-center {
	flex: 1 1 390px;
}

.footer-right {
	flex: 1 1 300px;
}
@media (max-width: 1225px) {
	.footer-left, .footer-right {
		padding-right: 20px;
		padding-left: 20px;
	}
}
@media (max-width: 746px) {
	.footer-left {
		order: 3;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		margin-bottom: 20px;
	}
	.footer-center {
		order: 1;
	}
	.footer-right {
		order: 2;
		margin-bottom: 20px;
	}
}
.frame-txt {
	background: url("../images/bottom-frame.webp") center top no-repeat;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	min-height: 360px;
}
.container-lam {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.lam {
	flex: 0 0 150px;
	margin: 0 10px 10px 10px;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
}
.profil-categories {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.profil {
	margin: 0 10px 10px 10px;
}
.profils {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.profil-item {
	flex: 1 1 130px;
	margin: 0 10px 10px 10px;
	text-align: center;
}
.profil-item img {
	max-width: 250px;
	max-height: 250px;
}
.profil-cat-text {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}
.profil-img {
	width: 17%;
	margin-right: 1%;
}
.profil-txt {
	width: 80%;
}
@media (max-width: 620px) {
	.profil-img {
		display: none;
	}
	.profil-txt {
		width: 100%;
	}
}
.profil-full {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}
.col-4-left {
	width: 26%;
	margin-right: 4%;
}
.col-4-left img {
	padding: 4px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
}
.col-8-right {
	width: 70%;
}
@media (max-width: 600px) {
	.col-4-left {
		width: 100%;
	}
	.col-8-right {
		width: 100%;
	}
}
.container-furnitura {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.furnitura {
	flex: 0 0 200px;
	margin: 0 10px 10px 10px;
}
.articles {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}
.article {
    flex: 1 1 250px;
    margin: 10px;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.simple-review {
    align-self: center;
    text-align: center;
}

.review-date {
    margin: 10px auto;
    text-align: center;
}

.container-contact {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.left-contacts {
	width: 48%;
	padding-right: 1%;
    margin-bottom: 10px;
}
.right-contacts {
	width: 48%;
	padding-left: 1%;
}
@media (max-width: 748px) {
	.left-contacts {
		width: 96%;
		padding-right: 1%;
		padding-left: 1%;
	}
	.right-contacts {
		width: 96%;
		padding-right: 1%;
		padding-left: 1%;
	}
}
.spadding {
	padding-bottom: 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
}
.txtac {
	text-align: center;
	padding-bottom: 18px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}
.addReviewDivButton {
    margin-bottom: 20px;
}
.all-reviews {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
}
.simple-reviews {
    grid-column: 1 / 13;
    display: grid;
    grid-template-columns: 2fr 9fr;
    grid-gap: 20px;
    border-top: 3px solid #9fd54e;
    border-bottom: 3px solid #9fd54e;
    padding: 12px;
    margin-bottom: 28px;
    background: #fff;
}
.simple-reviews img {
	align-self: center;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
}
.video-reviews {
	grid-column: span 6;
}
@media (max-width: 600px) {
	.video-reviews {
		grid-column: span 13;
	}
	.simple-reviews {
		grid-column: 1 / 13;
		display: grid;
		grid-template-columns: 12fr;
		grid-gap: 20px;
	}
	.simple-reviews img {
		margin: auto;
	}
}
.container-job {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
}
.item-job {
	text-align: center;
}
input[type=text], input[type=tel], input[type=email], input[type=number], input[type=password] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}

textarea {
	width: 100%;
	height: 150px;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #f8f8f8;
	resize: none;
}

button.btn-submit {
    width: 100%;
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
}

button.btn-submit2 {
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
}

button.btn-submit3 {
    background: linear-gradient(to top, #d4c32e, #f4e034 50%, #d4c32e 75%);
    color: black;
    padding: 14px 20px;
    margin: 8px 0;
    border: 2px solid #d4c32e;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    margin-top: 30px;
}

    button.btn-submit3:hover {
        background: none;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: 2px solid white;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        text-transform: uppercase;
        margin-top: 30px;
    }

    button.btn-submit33 {
        background: none;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: 2px solid white;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        text-transform: uppercase;
        margin-top: 30px;
    }

        button.btn-submit33:hover {
            background: linear-gradient(to top, #d4c32e, #f4e034 50%, #d4c32e 75%);
            color: black;
            padding: 14px 20px;
            margin: 8px 0;
            border: 2px solid #d4c32e;
            border-radius: 4px;
            cursor: pointer;
            font-size: 20px;
            font-family: 'Roboto Condensed', sans-serif;
            text-transform: uppercase;
            margin-top: 30px;
        }

button.btn-submit-blue {
	width: 100%;
	background-color: #5288b6;
	color: white;
	padding: 14px 20px;
	margin: 8px 0;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}
a.btn-submit {
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin: 20px 0;
    text-align: center;
}

a.btn-submit-estimate {
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    color: white;
    padding: 14px 20px;
    /*margin: 8px 0;*/
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin: 20px 0;
    text-align: center;
}

/*
Пагинация bootstrap
 */

.pagination-center {
	text-align: center;
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
}
.pagination > li {
	display: inline;
}
.pagination > li > a,
.pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	line-height: 1.42857143;
	text-decoration: none;
	color: #337ab7;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	z-index: 2;
	color: #23527c;
	background-color: #eeeeee;
	border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	z-index: 3;
	color: #ffffff;
	background-color: #337ab7;
	border-color: #337ab7;
	cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	color: #777777;
	background-color: #ffffff;
	border-color: #dddddd;
	cursor: not-allowed;
}

.trust {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
}

.conf {
    font-size: 12px;
}

/*
Главное меню
 */
nav {
    background: linear-gradient(#0280b5, #02527d);
}
nav a {
	text-decoration: none;
}
@media (min-width: 701px) {
	.menu {
		display: flex;
		flex-flow: row nowrap;
		min-height: 38px;
		max-width: 1280px;
		margin: auto;
		text-align: center;
		align-items: center;
	}

	.drop-item {
		position: relative;
		color: white;
		line-height: 38px;
		flex: 1 1 auto;
	}

	.drop-item:hover {
		background-color: #003f5c;
	}

	.drop-a {
		cursor: pointer;
	}

	.menu > a {
		color: white;
		line-height: 38px;
		flex: 1 1 auto;
	}

	.menu > a:hover, .menu > a.active {
		background-color: #003f5c;
	}

	.child-menu {
		display: none;
		top: 38px;
		z-index: 9999;
		position: absolute;
		background-color: #fff;
		border-left: 7px solid #005a85;
		border-right: 1px dashed lightgray;
		min-width: 200px;
	}

	.child-menu a {
		color: black;
		line-height: 38px;
		display: block;
		padding: 0 10px;
		border-bottom: 1px dashed lightgray;
	}

	.child-menu a:hover {
		background-color: #e7e7e7;
	}

	.icon-mobile {
		display: none;
	}
}

@media (max-width: 700px) {
	.menu {
		display: flex;
		flex-flow: column wrap;
		min-height: 38px;
		width: 100%;
		margin: auto;
		text-align: center;
		align-items: center;
	}

	.menu * {
		display: none;
	}

	.menu a.icon-mobile {
		display: block;
		color: white;
		line-height: 38px;
		width: 100%;
	}

	.menu a.icon-mobile:hover {
		background-color: #003f5c;
	}

	/*
	Мобильное меню
	 */

	.menu.responsive {
		display: flex;
		flex-flow: column wrap;
		min-height: 38px;
		width: 100%;
		margin: auto;
		text-align: center;
		align-items: center;
	}

	.menu.responsive .drop-item {
		position: relative;
		color: white;
		line-height: 38px;
		width: 100%;
		display: block;
	}

	.menu.responsive .drop-item:hover {
		background-color: #003f5c;
	}

	.menu.responsive .drop-a {
		cursor: pointer;
		display: block;
		background-color: #11658d;

	}

	.menu.responsive > a {
		color: white;
		line-height: 38px;
		width: 100%;
		display: block;
		background-color: #11658d;
	}

	.menu.responsive > a:hover, .menu > a.active, .menu.responsive .drop-a:hover {
		background-color: #003f5c;
	}

	.menu.responsive .child-menu {
		display: none;
		background-color: #fff;
		width: 100%;
	}

	.menu.responsive .child-menu a {
		color: black;
		line-height: 38px;
		display: block;
		padding: 0 10px;
		border-bottom: 1px dashed lightgray;
	}

	.menu.responsive .child-menu a:hover {
		background-color: #e7e7e7;
	}
}

/*
Мобильная версия
 */

@media (min-width: 465px) and (max-width: 945px) {
	.logo {
		grid-column: 1 / 7;
		grid-row: 2;
		align-self: center;
		padding: 0;
		-ms-grid-column: 1;
		-ms-grid-column-span: 6;
		-ms-grid-column-align: center;
		-ms-grid-row: 2;
	}
	.right-top {
		grid-column: 7 / 13;
		grid-row: 2;
		justify-self: end;
		padding: 0;
		-ms-grid-column: 8;
		-ms-grid-column-span: 6;
		-ms-grid-column-align: center;
		-ms-grid-row: 2;
        padding-top: 10px;
        padding-bottom: 10px;
	}
	.tagline {
		grid-column: 1 / 13;
		grid-row: 3;
		align-self: end;
		-ms-grid-column: 1;
		-ms-grid-column-span: 12;
		-ms-grid-row-align: end;
		-ms-grid-row: 3;
	}
}

@media (max-width: 464px) {
	.logo {
		grid-column: 1 / 13;
		grid-row: 2;
		justify-self: center;
		padding: 0;
		-ms-grid-column: 1;
		-ms-grid-column-span: 12;
		-ms-grid-column-align: center;
		-ms-grid-row: 2;
	}
	.right-top {
		grid-column: 1 / 13;
		grid-row: 3;
		justify-self: center;
		padding: 0 10px 10px;
		-ms-grid-column: 1;
		-ms-grid-column-span: 12;
		-ms-grid-column-align: center;
		-ms-grid-row: 3;
	}
	.tagline {
		grid-column: 1 / 13;
		grid-row: 4;
		align-self: end;
		-ms-grid-column: 1;
		-ms-grid-column-span: 12;
		-ms-grid-column-align: center;
		-ms-grid-row: 4;
	}
}
.btn-default {
    padding: 10px;
    background-color: #487ca7;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 100%;
    display: block;
    border: none;
    cursor: pointer;
}

@media (max-width: 1193px) {
    .header-content {
        margin: 0 15px;
    }
}

@media (max-width: 464px) {
    .header-content {
        margin: 0;
    }
}


@media (max-width: 743px) {
    .green-70 {
		font-size: 22px;
		color: #109e42;
		font-weight: 700;
		margin-top: 5px;
	}
}

@media (max-width: 567px) {
    .sale-70 {
		font-size: 22px;
		color: #0a729e;
		font-weight: 700;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.profilsBox {
    border-top: 10px solid #505767;
    box-shadow: 0 0 13px 3px rgba(0,0,0,0.27);
    background-color: #f9f9f9;
    position: relative;
    padding-bottom: 30px;
}

.badge {
    background: url(../images/badge.webp) top center no-repeat;
    color: #fff;
    width: 62px;
    height: 37px;
    position: absolute;
    font-weight: 700;
    font-size: 18px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    padding: .25em .4em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.profileCatName {
    padding: 0 0 5px 0;
    margin: 5px 10px;
    font-size: 18px;
    border: none;
    border-radius: inherit;
    text-transform: uppercase;
    color: #686868;
    border-bottom: 1px dashed #c5c5c5;
    transition: .6s;
    cursor: pointer;
}

    .profileCatName:hover {
        color: #4ac54a;
        border-color: transparent
    }

.profilDetails {
    max-width: 1280px;
    margin: auto;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 400px 1fr;
}

    .profilDetails > * {
        min-width: 0;
    }

#profileImg {
    display: flex;
    justify-content: center;
}

#nameProfile {
    font-size: 24px;
    text-transform: uppercase;
}

.compireTablePr {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
}

    .table tr {
        cursor: pointer;
    }

.table td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.compareProfile {
    color: #4ac54a;
    display: inline-block;
    border-bottom: 1px dashed #4ac54a;
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: .6s;
    font-size: 20px;
}

.ulProfils > div {
    margin-bottom: 10px;
}

table {
    border-collapse: collapse
}

table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    text-align: center;
}

    table th, table td {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6
    }

    table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6
    }

    table tbody + tbody {
        border-top: 2px solid #dee2e6
    }

    table table {
        background-color: #fff
    }

    table th, table td {
        padding: .3rem
    }

table {
    border: 1px solid #dee2e6
}

    table th, table td {
        border: 1px solid #dee2e6
    }

    table thead th, table thead td {
        border-bottom-width: 2px
    }

    table tbody tr:nth-of-type(odd) {
        background-color: rgba(0,0,0,.05)
    }

@media(max-width: 575.98px) {
    table {
        box-sizing: border-box;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
}

@media (max-width: 928px) {
    .profilDetails {
        grid-template-columns: 1fr;
        margin: 0 30px;
    }
    .profileCatName {
        font-size: 16px;
    }
    #count {
        display: none;
    }
}

.blueThank {
    font-size: 24px;
    color: #0b6b8e;
}

.saleText {
    font-size: 24px;
    margin: 20px auto;
    text-align: center;
}

.saleGreen {
    font-size: 34px;
    color: #109e42;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}

.measurement {
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 105px;
    z-index: 15;
    padding: 10px;
    font-size: 18px;
    text-transform: uppercase;
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15), 0 -3px 5px 0 rgba(0,0,0,0.15);
    color: white;
    border-radius: 20px 0 0 20px;
    text-align: center;
    display: none;
}

@media (max-width: 588px) {
    .measurement {
        display: none;
    }
}

a.btnSubmit {
    background: linear-gradient(to top,#68ad2c,#7cc141 50%,#67ad2a 75%);
    color: #fff;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed',sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin: 20px 0;
    text-align: center;
}

    a.btnSubmit:hover {
        background: linear-gradient(to top,#629e2d,#689e39 50%,#629e2d 75%);
    }

.videoClass {
    position: relative;
    max-height: 700px;
    overflow: hidden;
}

#myVideo {
    position: relative;
    max-width: 1300px;
    width: 100%;
    height: 100%;
}

.textBlock {
    background-color: white;
    position: relative;
    top: -10px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 0 1px;
}

.fusionText {
    font-size: 20px;
    text-align: center;
    font-style: italic;
    background-color: white;
    padding: 20px 30px;
    border-top: 3px solid rgb(252, 117, 35);
}

.noticeText {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 25px;
    margin: 20px 0;
    padding: 0 30px;
}

    .noticeText > div {
        border-top: 3px solid rgb(252, 117, 35);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 8px 0px;
        text-align: center;
        padding: 20px;
    }

    .mainMiddleText {
        max-width: 780px;
        margin: 50px auto;
        padding: 0 30px;
    }

.lpTheme {
    font-size: 18px;
    line-height: 1.8;
    letter-spacing: 0.2px;
    color: #4a4640;
}

.lpTheme p {
    text-align: left;
}

.galleryText {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    grid-gap: 0px;
}

@media (max-width: 480px) {
    .galleryText {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 0px;
    }
}

.imgBgFusion {
    background-color: rgba(255,255,255,0);
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 20%;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.freeMeasurerCall {
    position: relative;
    min-height: 400px;
    margin-top: 0px;
    margin-bottom: -20px;
    width: 488px;
}

@media (max-width: 570px) {
    .freeMeasurerCall {
        width: 100%;
    }
}

.textFusionBox {
    background-color: #f7f7f2;
    overflow: hidden;
    box-shadow: rgb(137, 137, 137) 10px 10px 33px -20px;
    padding: 60px 6%;
    border-radius: 8px;
    height: auto;
    text-align: center;
}

.textBlockSubscribe {
    background: linear-gradient(to right, #6cb1308f, #f5f5f5, #5389b6a6);
    padding-top: 7%;
    padding-right: 25px;
    padding-bottom: 7%;
    padding-left: 25px;
    margin-top: 40px;
    text-align: center;
}

.socialText {
    margin-top: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-bottom: 55px;
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 10px;
}

    .socialText img {
        padding: 10px;
    }

    .footerSlider {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 10px;
        justify-items: center;
    }

.orangeButton {
    color: #ffffff;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 2px;
    background: #fc7523;
    border: none;
    font-weight: 500;
    transition: background 1s;
    cursor: pointer;
}
    .orangeButton:hover {
        background: #ee5827;
    }

.inputLpHorisontal {
    display: inline-block;
}

    .inputLpHorisontal input {
        float: left;
        margin-top: 15px;
        margin-right: 10px;
    }

    .inputLpHorisontal button {
        float: left;
        margin-top: 15px;
        height: 56px;
    }

.lpTheme input {
    padding-top: 0;
    padding-bottom: 0;
    height: 56px;
    background-color: #ffffff;
    font-size: 16px;
    color: #aaaaaa;
    border-width: 2px;
    border-color: #e0e0de;
    border-radius: 6px;
    width: unset;
}

    .lpTheme input:focus {
        border-color: #fc7523;
    }

.lpTheme textarea {
    border-width: 2px;
    border-color: #e0e0de;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 16px;
    color: #aaaaaa;
    padding: 12px 15px;
    width: 100%;
    height: 150px;
}

    .lpTheme textarea:focus {
        border-color: #fc7523;
    }

.sendLetter {
    margin-top: 40px;
}

.treeInput {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-bottom: 10px;
}

.treeInput input {
    width: 33%;
}

.btn-primary {
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
}

.form-group > input[type=file] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.row .form-group {
    margin-bottom: 10px;
}

.noticeText p {
    text-align: center;
}

.fusionText p {
    text-align: center;
}

.galleryText img {
    display: block;
}

.linkHover {
    position: relative;
    max-width: 500px;
}

    .linkHover img {
        display: block;
    }

.itemBackground {
    opacity: 0;
    background-color: #5389b6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    max-width: 500px;
    cursor: pointer;
    color: white;
    transition: opacity 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
}

.linkHover:hover .itemBackground {
    opacity: .9;
}

.sText {
    padding: 30px;
    border-top: 3px solid rgb(10, 114, 158);
    background-color: white;
    margin-top: 40px;
}

.hotInput input[type=text], .hotInput input[type=tel], .hotInput input[type=email] {
    border: 3px solid #d0d0d0;
    font-size: 24px;
}

.flexRia {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.webDesign a {
    color: #fff;
    text-decoration: none;
}

.whiteAddress {
    color: white;
}

@media (max-width: 468px) {
    .lpTheme table {
        display: block;
        width: 100%;
        overflow-x: auto;
        border: none;
    }
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

    .custom-select:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
    }

        .custom-select:focus::-ms-value {
            color: #495057;
            background-color: #fff;
        }

    .custom-select[multiple], .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none;
    }

    .custom-select:disabled {
        color: #6c757d;
        background-color: #e9ecef;
    }

    .custom-select::-ms-expand {
        opacity: 0;
    }

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.sName {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    background-color: #eeeeee;
}

.breadcrumbs {
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
}

.breadcrumbs li {
    margin-right: 30px;
}

.breadcrumbs a {
    color: #232323;
}

input[type=text].hidden {
    display: none;
}

.greenBg {
    background: rgba(94, 187, 94, 0.41);
}

table tbody tr:nth-of-type(odd).greenBg {
    background: rgba(94, 187, 94, 0.41);
}

.name-profile {
    color: rgb(74, 197, 74);
}

.hidden {
    display: none !important;
}

.promotion-text p {
    text-align: center;
    font-size: 18px;
}

.promotion-img {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.promotion-img img {
    box-shadow: inset 0 0 10px #000000;
}

.bomb {
    position: fixed;
    left: 25px;
    bottom: 25px;
    cursor: pointer;
}

.countdown-bomb {
    position: fixed;
    left: 48px;
    bottom: 48px;
    color: white;
    font-size: 55px;
}

.countdown-bomb-one {
    position: fixed;
    left: 62px;
    bottom: 48px;
    color: white;
    font-size: 55px;
}

.countdown-bomb-click a {
    position: fixed;
    left: 42px;
    bottom: 62px;
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: none;
}

.explosion {
    position: absolute;
    top: -50px;
    left: 1px;
    white-space: nowrap;
    background: linear-gradient(to top, #68ad2c, #7cc141 50%, #67ad2a 75%);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
}

.spark1 {
    position: absolute;
    top: -5px;
    left: 100px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%);
    animation-name: spark1;
    animation-duration: 0.15s;
    animation-iteration-count: infinite;
}

@keyframes spark1 {
    0%   { top: -5px; left: 100px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    25%  { top: -10px; left: 95px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    50%  { top: -15px; left: 100px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    75%  { top: -20px; left: 105px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    100% { top: -25px; left: 100px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
}

.spark2 {
    position: absolute;
    top: -5px;
    left: 100px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%);
    animation-name: spark2;
    animation-duration: 0.19s;
    animation-iteration-count: infinite;
}

@keyframes spark2 {
    0%   { top: -1px; left: 105px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    25%  { top: -5px; left: 100px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    50%  { top: -9px; left: 105px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    75%  { top: -15px; left: 110px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    100% { top: -19px; left: 105px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
}

.spark3 {
    position: absolute;
    top: -5px;
    left: 100px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%);
    animation-name: spark3;
    animation-duration: 0.20s;
    animation-iteration-count: infinite;
}

@keyframes spark3 {
    0%   { top: 1px; left: 90px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    25%  { top: -5px; left: 85px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    50%  { top: -8px; left: 90px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    75%  { top: -14px; left: 95px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    100% { top: -18px; left: 100px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
}

.spark4 {
    position: absolute;
    top: -5px;
    left: 100px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%);
    animation-name: spark4;
    animation-duration: 0.15s;
    animation-iteration-count: infinite;
}

@keyframes spark4 {
    0%   { top: 2px; left: 93px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    25%  { top: -8px; left: 88px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    50%  { top: -16px; left: 93px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
    75%  { top: -22px; left: 98px; background: linear-gradient(to top, #ff3a46, #ad2a2a 50%, #ff3a46 75%); }
    100% { top: -27px; left: 91px; background: linear-gradient(to top, #ad2c2c, #ff3a46 50%, #ad2a2a 75%); }
}

.portfolio-tabs {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-bottom: 30px;
}

.portfolio-tabs > div {
    padding: 0 0 5px 0;
    margin: 5px 10px;
    font-size: 18px;
    border: none;
    border-radius: inherit;
    text-transform: uppercase;
    color: #686868;
    border-bottom: 1px dashed #c5c5c5;
    transition: .6s;
    cursor: pointer;
}

#map {
    width: 100%;
    height: 700px;
    border: 8px solid rgba(74,187,133,.4);
    box-sizing: border-box;
}

.job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(352px, 1fr));
    grid-gap: 30px;
    text-align: center;
}

@media (max-width: 700px) {
    .job-grid {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
}

.googleReCaptcha {
    margin-top: 10px;
    line-height: 20px;
    color: white;
    font-size: 14px;
}

.googleReCaptcha a {
    color: #ffffff;
}

.grecaptcha-badge {
    visibility: hidden;
}